import React, { useState, useRef, useEffect } from 'react'
import { Flipper, Flipped } from "react-flip-toolkit"
import { motion } from "framer-motion"
import UslugaKrok from './usluga_krok'
import ButtonPaluch from '../buttons/button_goto'
import typo from '../../images/podstrony/uslugi/okladka_tekst_design@2x.png'
import branding_bg from '../../images/podstrony/uslugi/okladka_design@2x.png'
import gwiazda from '../../images/ikony_tekst/gwiazda_prosta.svg'
import korzysci from '../../images/podstrony/uslugi/korzysci.svg'
import strzalka from '../../images/podstrony/uslugi/strzalka_wspolpraca.svg'
import maska from '../../images/ikony/maska_biala.svg'
import Slider from '../slider/slider'
import back from '../../images/ikony/goback.svg'
import TransitionLink from 'gatsby-plugin-transition-link'
import Seo from '../seo'
import okladka from '../../images/seo/design.jpg'
import InneUslugi from './inne'
import Rekomendacje from '../rekomendacje'
import Formularz from '../formularz/formularz'

import paluch from '../../images/ikony/paluch.svg'


import tracker_poczatek from '../../images/nowy_tracker/poczatek@2x.png'
import tracker from '../../images/nowy_tracker/tracker@2x.png'
import krok1 from '../../images/podstrony/uslugi/design/1.svg'
import krok2 from '../../images/podstrony/uslugi/design/2.svg'
import krok3 from '../../images/podstrony/uslugi/design/3.svg'
import krok4 from '../../images/podstrony/uslugi/design/4.svg'
import krok5 from '../../images/podstrony/uslugi/design/5.svg'
import krok6 from '../../images/podstrony/uslugi/design/6.svg'
import krok7 from '../../images/podstrony/uslugi/design/6.svg'

const UslugaDesign = ({title, entry}) => {    

    const [krokAktywny, ustawKrok] = useState();

    const kroki = useRef()
    const ref1 = useRef(null)
    const ref2 = useRef(null)
    const ref3 = useRef(null)
    const ref4 = useRef(null)
    const ref5 = useRef(null)
    const ref6 = useRef(null)
    const pas = useRef()

    var refs = [ref1, ref2, ref3, ref4]

    var pasCrop;


    useEffect(() => {

  
        
        
        return () => {

        }
    }, []);

    

    const list = ['Corporate design', 'UX/UI', 'Digital design & Print', 'Ilustracja', 'Produkcja'];
    const item = {
        visible: { opacity: 1, x: 0 },
        hidden: { opacity: 0, x: -100 },
    }
    const item2 = {
        visible: { opacity: 1, x: 0 },
        hidden: { opacity: 0, x: 100 },
    }


    function LinkPrzycisk(props){

        if(props.tekst) {
          return(
            <a href={props.link} className="bread-link">
       
                <img src={paluch} />
                <span className="przycisk">{props.tekst}</span>
           
            </a>
          )
        }
        else{ 
          return(<></>)
        }
            
        
    
         
        }



    const variants = {
        visible: i => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: i * 0.3 + 0.6,
            },
        }),
        hidden: { opacity: 0, y: 50 },
    }


    useEffect(() => {

        
        console.log(Slider);

     
    }, [krokAktywny]);








    return (
        <>
         <Seo title="DESIGN" description="Projektujemy wyjątkowe doświadczenia. Dostarczamy Ci kompleksowe rozwiązania graficzne. Tworzymy produkty cyfrowe i fizyczne." image={okladka} />
        <div className="usluga-okladka-wrapper">
                        <img className="usluga-okladka" src={typo} />
                        <img className="usluga-okladka" src={branding_bg} />
                        </div>
        <div className="podstrona-wrapper" id="usluga-info" onClick={() => (console.log({ krokAktywny }))}>

        

            <div className="usluga-wrapper usluga-aktywna">
                <div id="usluga-rzad" className="usluga-zaladowana">
                    <div className="usluga-rzad">
                        <TransitionLink 
                        state={{ title: 'USŁUGI' }}
                        activeClassName="podstrona-aktywna"
                        to="/uslugi"
                        partiallyActive={true}
                        exit={{
                          length: 1,
                          state: { x: -entry.state.len},
                        }}
                        entry={{
                          length: 1,             
                          state:  {x: (entry.state.len), len: entry.state.len},
                        }}
                        
                        
                        >
                            <img

                                src={back}
                                className="usluga-wroc"
                            />
                        </TransitionLink>

                        <div className="usluga-header-maska"></div>
                        <h1>{title}</h1>
                        <ul>

                            {list.map((l) => (
                                <p
                                    key={l}




                                >{l}</p>
                            ))}


                        </ul>
                        <div



                            className="usluga-przycisk" style={{
                                height: '100%'
                            }}>
                            <ButtonPaluch
                                text="ZOBACZ WIĘCEJ" />
                        </div>
                        <div className="usluga-linia-bread"></div>
                        <div className="bread-dodatkowe">
                        <LinkPrzycisk  link="/formularz" tekst="KONTAKT" />
                        <LinkPrzycisk  link="/projekty" tekst="PROJEKTY" />
                        </div>
                    </div>



                    <div 
                     className='usluga-opis-wrapper' >

                        
                        
                        <motion.div
                            
                            animate={{ y: 0 }}
                            transition={{ ease: "easeOut", duration: 2 }}
                            style={{   position: 'relative' }}
                            className={(!krokAktywny ? "usluga-wjazd" : "usluga-wjazd kroki-aktywne")}
                            >
                            <h6>Projektujemy wyjątkowe doświadczenia</h6>
      <h4>Dostarczamy kompleksowe rozwiązania graficzne. Podstawą naszego działania jest rozległa wiedza z zakresu projektowania graficznego, produkcji mediów a także marketingu, ze szczególnym uwzględnieniem brandingu i komunikacji. Dzięki temu dostarczane projekty są nie tylko wyjątkowe w odbiorze i estetycznie ale przede wszystkim przemyślane na gruncie merytorycznym. Tworzymy produkty cyfrowe i fizyczne. Realizujemy pojedyncze projekty lub obsługujemy Twoją firmę stale, na zasadach ryczałtu. </h4>
                            
                        
                            <div className="usluga-kroki">
                                <h6>Nasze kompetencje</h6>


                                <div>
                                    <div ref={ref1} className="krok-wrap" id="krok1">

                                        <UslugaKrok
                                        img={krok1}
                                        tytul="Identyfikacja wizualna"
                                        podtytul="Inspirujące doświadczenie marki"
                                        tekst="Marki to doświadczenia a z interesującymi Cię doświadczeniami chcesz się identyﬁkować. Ułatwiamy to Twoim klientom tworząc systemy identyﬁkacji, które są wyjątkowe wizualnie i opracowane w taki sposób, by być maksymalnie spójnymi w wykorzystywanych przez markę środkach przekazu. Na tym etapie opiszemy podstawowe elementy identyifkacji Twojej ﬁrmy (znak, barwa, typograﬁa, etc.), i stworzymy szereg niezbędnych materiałów promocyjnych."
                                        
                                        krokId="1" krokAktywny={krokAktywny} ustawKrok={ustawKrok} />
                                    </div>
                                    <div ref={ref2} className="krok-wrap" id="krok2">

                                        <UslugaKrok 
                                         img={krok2}
                                        tytul="Corporate design"
                                        podtytul="Projektujemy z myślą o Twoim biznesie"
                                        tekst="Dostarczamy kompleksowych rozwiązań graficznych dla Twojego biznesu. Materiały biznesowe, druki akcydensowe, treści do mediów czy cokolwiek innego, co należy zaprojektować i wyprodukować - z nami masz wszystko w jednym miejscu. Dysponujemy zespołem o pełnym wachlarzu kompetencji projektowych, w związku z czym żadna realizacja nie będzie dla nas przeszkodą. Współpracujemy z drukarniami, producentami gadżetów, szwalniami i dedykowanymi warsztatami z całej Polski. Zajmujemy się Twoimi projektami od etapu koncepcji do dostarczenia gotowych produktów do Twojej firmy." krokId="2" krokAktywny={krokAktywny} ustawKrok={ustawKrok} />
                                    </div>
                                    <div ref={ref3} className="krok-wrap" id="krok3">

                                        <UslugaKrok 
                                         img={krok3}
                                        tytul="UX/UI"
                                        podtytul="Rozwiązania cyfrowe stworzone z myślą o Twoich klientach"
                                        tekst="Tworzymy rozwiązania cyfrowe stworzone na miarę celów Twojej firmy i potrzeb Twoich użytkowników. Pomaga nam w tym gruntowna analiza sytuacji, na którą odpowiedzią mają być przygotowywane narzędzia oraz system, który tworzony jest w taki sposób, by jego użytkowanie było przyjemnością. Tworzymy zarówno skomplikowane narzędzia cyfrowe, duże portale korporacyjne jak i strony wizytówkowe oraz projekty eksperymentalne, skupione na wykorzystaniu najnowszych technologii. Każdy projekt wyceniamy indywidualnie i współpracujemy z najlepszymi specjalistami z zakresu UX/UI oraz web development." 
                                        krokId="3" krokAktywny={krokAktywny} ustawKrok={ustawKrok} />
                                    </div>
                                    <div ref={ref4} className="krok-wrap" id="krok4">

                                    <UslugaKrok
                                     img={krok4}
                                    tytul="Digital"
                                    podtytul="Dbamy o Twój cyfrowy wizerunek"
                                    tekst="Projektujemy Twoje materiały cyfrowe. Od treści do mediów społecznościowych, stron www przez prezentacje biznesowe, oferty sprzedażowe czy katalogi, aż do animacji. Wspieramy Cię w wielu wymiarach (2D, 3D), na wszystkich nośnikach. Jeżeli potrzebujesz coś wyświetlić, wrzucić do internetu, możemy to dla Ciebie przygotować. Jeżeli potrzebujesz materiałów fizycznych - zapoznaj się z zakładką print." 
                                    krokId="4" krokAktywny={krokAktywny} ustawKrok={ustawKrok} />
                                    </div>

                                    <div ref={ref5} className="krok-wrap" id="krok4">

                                    <UslugaKrok
                                     img={krok5}
                                    tytul="Print"
                                    podtytul="Inspirujące doświadczenie marki"
                                    tekst="Realizujemy działania z obszaru druku - od etapu koncepcji i projektu do produkcji. Tworzymy wyjątkowe rozwiązania, unikamy szablonów i powielania pomysłów bo zależy nam na niepowtarzalności Twojej marki. Przez kilkanaście lat pracy w branży zbudowaliśmy relacje z producentami właściwie wszystkiego, co możesz potrzebować do promocji Twojej marki. Produkcja skomplikowanych druków biznesowych - nie ma sprawy. Stanowiska targowe, produkcja odzieży, gadżety reklamowe - dostarczymy pod Twoje drzwi." 
                                    krokId="5" krokAktywny={krokAktywny} ustawKrok={ustawKrok} />
                                    </div>

                                    <div ref={ref5} className="krok-wrap" id="krok4">

                                    <UslugaKrok
                                     img={krok6}
                                    tytul="Editorial"
                                    podtytul="Codzienne wsparcie Twojej marki"
                                    tekst="Publikacje, katalogi, oferty, prezentacje, książki czy magazyny. Jako biznes wydajesz dużo treści, które często są fundamentem m.in.  działań sprzedażowych lub wizerunkowych Twojej firmy. W związku z tym ich jakość jest kluczowa i może stanowić o powodzeniu prowadzonych działań. W Odrze przywiązujemy ogromną wagę do jakości, systemowości i doświadczenia z Twoimi publikacjami i realizujemy je kompleksowo - od omówienia potrzeb, przez makietowanie, projekt aż do produkcji." 
                                    krokId="6" krokAktywny={krokAktywny} ustawKrok={ustawKrok} />
                                    </div>

                                    <div ref={ref5} className="krok-wrap" id="krok4">

                                    <UslugaKrok
                                     img={krok7}
                                    tytul="Ilustracja"
                                    podtytul="Angażujący język wizualny Twojej marki"
                                    tekst="Ilustracja to fantastyczne narzędzie, które nie tylko potrafi oddać charakter marki, opowiadać Twoje historie, zwracać uwagę na ważne elementy czy budować wyjątkowe doświadczenia. To też rozwiązanie dające Ci szansę na optymalizację materiałów, zwizualizowanie elementów, których nie można sfotografować, uprościć język wizualny i budować porozumienie z Twoimi klientami. W Odrze współpracujemy z najlepszymi ilustratorami w Polsce i jesteśmy w stanie dobrać rozwiązania bezpośrednio do Twoich potrzeb." 
                                    krokId="6" krokAktywny={krokAktywny} ustawKrok={ustawKrok} />
                                    </div>

                                </div>
                            </div>



                        </motion.div>

                        <div className="usluga-proces">
                            <h6>Proces</h6>
                            <div className="usluga-proces-tracker">
                                <img src={tracker_poczatek} />
                                <div className="tracker-pas">
                                    <div className="tracker-punkt"></div>
                                    <div className="tracker-punkt"></div>
                                    <div className="tracker-punkt"></div>
                                    <div className="tracker-punkt"></div>
                                    <div className="tracker-linia"></div>
                                    
                                </div>
                                <div className="tracker-wrapper">
                                <img className="tracker" src={tracker} />
                                </div>

                                
                                
                                
                            </div>

                            <div className="usluga-proces-kroki">
                                <div className="usluga-proces-krok">
                                    <span className="przycisk">1. ANALIZA</span>
                                    <p>Realna wiedza o Twojej organizacji, klientach i konkurencji jest kluczowa dla realizowania jakościowych projektów brandingowych. W Odrze jesteśmy gotowi by dostać się do sedna sprawy, z wykorzystaniem profesjonalnych metod badawczych</p>      
                                </div>
                                <div className="usluga-proces-krok">
                                    <span className="przycisk">2. KONCEPCJA</span>
                                    <p>Tworzymy dedykowane koncepcje oparte na danych pochodzących od Ciebie i z etapu badań. Nie powielamy schematów, bo wiemy jak ważna jest unikalność Twojego biznesu.</p>      
                                </div>
                                <div className="usluga-proces-krok">
                                    <span className="przycisk">3. PROJEKT</span>
                                    <p>Projektujemy z największą starannością, dbałością o detale i wyjątkowość Twojej komunikacji. Pracujemy z najlepszymi projektantami, ilustratorami i artystami.</p>      
                                </div>
                                <div className="usluga-proces-krok">
                                    <span className="przycisk">4. OBSŁUGA</span>
                                    <p>Po wdrożeniu koncepcji wciąż możesz liczyć na nasze wsparcie - doradzamy w kwestiach strategicznych, realizujemy zadania projektowe, wspieramy Cię w promocji Twojej marki zarówno online i offline. Poznaj usługi “design” i “media”, by dowiedzieć się więcej.</p>      
                                </div>
                            </div>

                        </div>

                        <div className="usluga-wspolpraca">
                         
                        <h6>Modele współpracy</h6>
                            <div>
                            <div className="box-szary">
                                <span className="przycisk">Model projektowy</span>
                                <div>
                                <div className="kolumna-info">
                                    <img src={korzysci} />
                                    <p>Praca w modelu projektowym najlepiej sprawdza się przy jednorazowych realizacjach, które posiadają określony zestaw składowych. W tym modelu przygotowujemy wstępną wycenę przed rozpoczęciem działań.</p>
                                </div>
                                <div className="kolumna-info">
                                 
                                    <p>Choć taki sposób działania wydaje się wygodny, należy pamiętać o tym, że jest on mniej odporny na zmiany w czasie trwania projektu. Tym, którym zależy na rozwijaniu realizowanych koncepcji rekomendujemy pracę w modelu ryczałtowym.</p>
                                </div>
                                </div>
                            </div>
                            <div className="box-szary">
                                <span className="przycisk">Model ryczałtowy - abonament</span>
                                <div>
                                <div className="kolumna-info">
                                    <img src={korzysci} />
                                    <p>W modelu ryczałtowym współpracujemy głównie z klientami, których obsługujemy w sposób ciągły. Polega on na ustaleniu określonej miesięcznej kwoty, która przeliczona jest na ilość godzin i kompetencje wykorzystywane w projekcie.</p>
                                </div>
                                <div className="kolumna-info">
                                  
                                    <p>Dzięki posiadanemu abonamentowi zawsze jesteśmy gotowi na Twoje briefy a Twoje zlecenia wpadają na początek kolejki. Taka forma współpracy pozwala na znacznie większą elastyczność i zapewnia bezpieczeństwo Twojej komunikacji.</p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>

                        <Rekomendacje />
                        

                        
                        
                    </div>
                    <Slider />
                </div>
            </div>
            <h6 className="zobacz-inne-uslugi" >Zobacz inne usługi</h6>
            
        </div>
        <InneUslugi usun="DESIGN" />
        <Formularz />
        </>
    )


}



export default UslugaDesign
